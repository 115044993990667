<template>
  <div>

    <b-card-code title="تفاصيل المستودع">

      {{warehouseName}}
      <validation-observer ref="simple">
  <b-row>
        <b-col xl="4" />
        <b-col xl="3">
          <b-button variant="purple" class="shadow mb-3" @click="download">
            <vue-excel-xlsx
              ref="childComponent"
              :data="Form"
              :columns="columns"
              :file-name="filename"
              :file-type="'xlsx'"
              :sheet-name="'sheetname'"
              class="button-excel"
            >
              <span> Export To Excel</span>
            </vue-excel-xlsx>
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mb-3">
          <b-col xl="2">
            <label>  </label>
          </b-col>
          <b-col md="3" class="mb-md-0 mb-2">
            <h6>من</h6>
            <flat-pickr
              v-model="start_date"
              class="form-control"
              
            />
          </b-col>
          <b-col md="3" class="mb-md-0 mb-2">
            <h6>إلى</h6>
            
            <flat-pickr
              v-model="end_date"
              class="form-control"
              
            />
          </b-col>
        </b-row>

 <b-table
        ref="refServiceListTable"
        class="position-relative"
        :items="Form"
        responsive
        :fields="tableColumns"
        
        show-empty
        empty-text="No matching records found"

      >
         <template #cell(out_quantity)="data">
          {{data.item.quantity - data.item.remaining_quantity}}
         </template>
 </b-table>
      
      </validation-observer>
    </b-card-code>

    <b-col
      md="4"
      xl="2"
    >
      <b-button
        variant="purple "
        @click="$router.go(-1)"
      >
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
 
  BButton,
  BForm,
  BFormInvalidFeedback,
  BTable
} from 'bootstrap-vue'

export default {

  components: {
    BFormInvalidFeedback,
  BTable,
    BForm,
    ValidationObserver,
    ValidationProvider,
    flatPickr,
    BCardCode,
    BButton,
    BFormInput,

    BFormGroup,
    BRow,

    BCol,
  },

  data() {
    return {
      name: '',
      number: '',
      donor_id: '',
      optionsDonor: [],
      Form: [],
      en_name: '',
      item_category_id: '',
//to filter 
      start_date: null,
      end_date:null,

      searchTerm: '',
      id: '',
       tableColumns :[

 
    { key: "item.name", label: "المادة"},
    { key: "unit.name", label: "الواحدة"},
    { key: "quantity", label: "الكمية"},
    {
          label:" رقم أمر الشراء",
         key : "po_number",
        },
        {
          label:"   الحالة",
         key : "status",
        },
         {
          label:"   القسم",
         key : "department_id",
        },
         {
          label:"   المستلم",
         key : "recived_by_user",
        },
        {
          label:"   اسم المورد",
         key : "vendor",
        },
         {
          label:"   رقم الفاتورة",
         key : "pill_number",
        },
        {
          label:"    تاريخ تسليم",
         key : "recive_date",
        },
    {
      key: "remaining_quantity",
      label: "الكمية المتبقية",
     

    },
    {
      key: "out_quantity",
      label: "الكمية المخرجة",
     

    }
  ],
   columns: [
       
        {
          label: "المادة",
          field: "item.name",
        },
        {
          label: "الواحدة",
          field: "unit.name",
        },
        {
          label: "الكمية",
          field: "quantity",
        },
         {
          label:" رقم أمر الشراء",
          field: "po_number",
        },
        
         {
          label:"   الحالة",
         field: "status",
        },
         {
          label:"   القسم",
         field : "department_id",
        },
         {
          label:"   المستلم",
         field : "recived_by_user",
        },
        {
          label:"   اسم المورد",
         field: "vendor",
        },
         {
          label:"   رقم الفاتورة",
         field: "pill_number",
        },
        {
          label:"    تاريخ تسليم",
         field: "recive_date",
        },
        {
          label: "الكمية المتبقية",
          field: "remaining_quantity",
        },

      ],
        filename: "warehouse-content",
        warehouseName:''

    }
  },

  created() {
    this.getVendor()
       this.$http.get("/api/v1/warehouses").then((res) => {
      //  console.log(res.data.data)
        this.warehouseName=res.data.data.find(el=>el.id==this.$route.params.id).name
        
       })
  },
  watch: {
"end_date"  : function (v) {
  this.getVendor()
  },
  "start_date" : function (v) {
  this.getVendor()
  }



},
  methods: {
       async download() {
      return new Promise((resolve, reject) => {
        
 this.$refs.childComponent.exportExcel();
      });
    },

    getVendor() {
      let query = {
        start_date: this.start_date,
        end_date: this.end_date
      }
      this.$http.get(`/api/v1/warehouse-content/${this.$route.params.id}`,  {params: query})
      .then(res => {
        //console.log('/warehouse-content', res.data.data)
        this.Form = res.data.data
      })
    },

    //
  },
}
</script>

  <style scoped>
  label {
    color: rgba(112, 192, 203, 255);
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .btn-purple {
    color: white;
    background-color: #7e7a9f;
    margin-top: 25px;
  }

  span {
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .align-left {
    left: 50px;
  }
  .but {
    top: 40px;
  }
  
.button-excel {
  border: none;
  padding: 0;
  background: none;
  color: white;
}

div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.media {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
</style>
